import React from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { BackgroundDivBgImage, Row, Col } from 'components/.base/containers';
import { Heading } from 'components/.base/headings';

import { Collapsible } from 'components/.base/Collapsible';
import { breakpoint, size } from 'constants/Breakpoint';
import { WoodenBorder } from 'components/.base/border';

const CntWrapper = styled.div`
  position: relative;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Faq = ({ bgImages, showStickyMenu, sectionRef, sectionData }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });
  return (
    <Row
      css={css`
        padding: 60px 0;
        position: relative;
        @media ${breakpoint.lg} {
          padding: 160px 0 60px;
        }
      `}
      ref={sectionRef}
    >
      <WoodenBorder top="0" />
      <WoodenBorder top="100%" />
      <BackgroundDivBgImage
        w="100%"
        h="100%"
        heightMobile="100%"
        top="0"
        topMobile="0"
        color="#edf2f2"
        style={{ zIndex: '-2' }}
      />
      {showStickyMenu && !isMobile && <Col size={1} className="stickySidebar" />}
      <Col size={4}>
        <CntWrapper>
          <Heading
            css={css`
              margin-bottom: 60px;
            `}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: sectionData.heading,
              }}
            ></span>
          </Heading>
          {sectionData.franchisePageFaqSectionFaqBlock.map((item, i) => (
            <Collapsible
              key={i}
              open={i === 0 ? true : false}
              heading={item.question.question}
              description={item.answer.answer}
            />
          ))}
        </CntWrapper>
      </Col>
    </Row>
  );
};

export default Faq;
