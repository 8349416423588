import React, { useCallback, useRef, useState } from 'react';
import uuid from 'react-uuid';

import { BodyText } from 'components/.base/headings';
import {
    CollapsibleButton,
    CollapsibleHeader,
    CollapsibleIcon,
    CollapsibleInner,
    CollapsibleItem,
    CollapsibleItemWrapper,
} from './Collapsible.styled';

const Collapsible = ({ open, heading, description }) => {
    const [expanded, setExpanded] = useState(open);

    const collapsibleInnerRef = useRef(null);

    const handleToggle = useCallback(() => {
        if (collapsibleInnerRef?.current) {
            if (expanded) {
                /**
                 * Slide up aims to close an element. To do that, we take the height of the element, and set it to 0 to
                 * force an animation
                 */
                collapsibleInnerRef.current.style.height =
                    collapsibleInnerRef.current.scrollHeight + 'px'; // Force previous height to allow CSS transition
                collapsibleInnerRef.current.offsetHeight; // Force redraw
                collapsibleInnerRef.current.style.height = 0;
            } else {
                /**
                 * Slide down aims to open an element. To do that, you must make sure that the element you are trying to open
                 * is set with height: 0; overflow: hidden in the CSS, and does not contain any padding nor margin.
                 */

                if (collapsibleInnerRef.current.style.height === 'auto') {
                    return;
                }

                // To do the animation we temporarily hide it, check the height, and transition to it
                collapsibleInnerRef.current.style.height =
                    collapsibleInnerRef.current.firstElementChild.scrollHeight + 'px';

                var transitionEnded = function transitionEnded(event) {
                    if (event.propertyName === 'height') {
                        collapsibleInnerRef.current.style.height = 'auto'; // Allows the content to grow normally
                        collapsibleInnerRef.current.removeEventListener(
                            'transitionend',
                            transitionEnded
                        );
                    }
                };

                collapsibleInnerRef.current.addEventListener('transitionend', transitionEnded);
            }
            setExpanded(!expanded);
        }
    }, [collapsibleInnerRef, expanded]);

    const id = uuid();

    return (
        heading && (
            <CollapsibleItem aria-expanded={expanded ? 'true' : 'false'}>
                <CollapsibleItemWrapper>
                    <CollapsibleHeader>
                        <CollapsibleButton
                            aria-expanded={expanded ? 'true' : 'false'}
                            aria-controls={id}
                            as="button"
                            onClick={handleToggle}
                        >
                            {heading}
                            <CollapsibleIcon />
                        </CollapsibleButton>
                    </CollapsibleHeader>

                    <CollapsibleInner
                        id={id}
                        ref={collapsibleInnerRef}
                        aria-hidden={expanded ? 'false' : 'true'}
                        role="region"
                        tabindex={expanded ? '0' : '-1'}
                        css={css`
                            ${expanded
                                ? `
                                height: auto;
                            `
                                : ''}
                        `}
                    >
                        <BodyText
                            as="div"
                            css={css`
                                padding: 25px 0 20px 0;
                            `}
                        >
                            {description}
                        </BodyText>
                    </CollapsibleInner>
                </CollapsibleItemWrapper>
            </CollapsibleItem>
        )
    );
};

export {
    Collapsible,
    CollapsibleIcon,
    CollapsibleItem,
    CollapsibleItemWrapper,
    CollapsibleButton,
    CollapsibleInner,
};
