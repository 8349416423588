import React, { useEffect } from 'react';
import Image from 'gatsby-image';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { breakpoint, size } from '../../../constants/Breakpoint';
import { Heading, Subheading, HeadingLarge, BodyText } from '~/components/.base/headings';
import Slideshow from '~/components/.base/slideshow';
import { BackgroundDivBgImage, Row, Col, CenteredContainer } from '~/components/.base/containers';

import { WoodenBorder } from '../../.base/border';

const HeaderCol = styled(Col)`
  padding: 0 20px;
`;

const SectionHeading = styled(HeadingLarge)`
  @media ${breakpoint.md} {
    text-align: center;
  }
`;

const WhyCml = ({ bgImages, showStickyMenu, sectionRef, sectionData }) => {
  return sectionData.whyCml ? (
    <div className={`why-cml`} ref={sectionRef}>
      <Row>
        <Col
          size={1}
          className="stickySidebar"
          css={`
            display: none;
            ${showStickyMenu
              ? `
                @media ${breakpoint.lg} {
                    display: block;                    
                }
            `
              : ``}
          `}
        />
        <HeaderCol size={4}>
          <SectionHeading as="h2" css={css``}>
            <span
              dangerouslySetInnerHTML={{
                __html: sectionData.heading,
              }}
            ></span>
          </SectionHeading>
        </HeaderCol>
      </Row>
      <div
        css={css`
          position: relative;
        `}
      >
        <WoodenBorder top="100px" />
        <WoodenBorder top="calc(80% + 100px)" />
        <WoodenBorder />
        <BackgroundDivBgImage
          w="100%"
          h="80%"
          heightMobile="80%"
          top="100px"
          topMobile="100px"
          color="#edf2f2"
          style={{ zIndex: '-2' }}
        />
        {sectionData.whyCml.map((block, i) => (
          <Row key={i}>
            <Col
              size={1}
              className="stickySidebar"
              css={`
                display: none;
                ${showStickyMenu
                  ? `
                  @media ${breakpoint.lg} {
                      display: block;                    
                  }
              `
                  : ``}
              `}
            />
            <Col size={4}>
              <Row
                className="upper-module"
                key={i}
                reverse={i % 2 !== 0}
                css={css`
                  flex-direction: column;
                  @media ${breakpoint.lg} {
                    flex-direction: ${i % 2 !== 0 ? 'row-reverse' : 'row'};
                  }
                  img {
                    width: 100%;
                    height: auto;
                  }
                `}
              >
                <Col
                  size="1"
                  css={css`
                    padding: 2.5rem 20px;
                    @media ${breakpoint.lg} {
                      max-width: 50%;
                      padding: 2.5rem;
                    }
                  `}
                >
                  <CenteredContainer
                    css={css`
                      align-items: flex-start;
                    `}
                    className="half-section-details"
                  >
                    <Heading
                      as="h3"
                      css={css`
                        margin-bottom: 20px;
                        width: 100%;
                      `}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: block.heading,
                        }}
                      ></span>
                    </Heading>
                    <Subheading
                      as="h4"
                      css={css`
                        margin-bottom: 20px;
                      `}
                    >
                      {block.subheading}
                    </Subheading>
                    <BodyText
                      css={css`
                        line-height: 1.5;
                      `}
                    >
                      {block.description.description}
                    </BodyText>
                  </CenteredContainer>
                </Col>
                <Col
                  size="1"
                  css={css`
                    padding: 2.5rem 20px;
                    @media ${breakpoint.lg} {
                      max-width: 50%;
                      padding: 2.5rem;
                    }
                  `}
                >
                  {block.image.length > 1 ? (
                    <Slideshow carouselLabel={block.title}>
                      {block.image.map((i, index) => (
                        <div
                          css={css`
                            border-radius: 30px;
                            overflow: hidden;
                          `}
                          className="image-cont"
                          key={index}
                        >
                          <Image fluid={i.fluid} alt={i.description} />
                        </div>
                      ))}
                    </Slideshow>
                  ) : (
                    <div
                      css={css`
                        border-radius: 30px;
                        overflow: hidden;
                      `}
                      className="image-cont"
                    >
                      <Image fluid={block.image[0].fluid} alt={block.image[0].description} />
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  ) : (
    ''
  );
};

export default WhyCml;
