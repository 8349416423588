import React, { useEffect } from 'react';
import { BodyTextAlt } from '~/components/.base/headings';
import './StickyMenu.scss';
const StickyMenu = ({
  whyCmlInView,
  quotesInView,
  asSeenInView,
  supportInView,
  stepsInView,
  joinFormInView,
}) => {
  const handleMenuItemClick = e => {
    e.preventDefault();
    const scrollToElm = document.querySelector(e.currentTarget.getAttribute('href'));
    scrollToElm.scrollIntoView({
      behavior: 'smooth',
    });
    scrollToElm.focus({ preventScroll: true });
  };
  useEffect(() => {
    if (window && document) {
      const checkMenuScroll = function () {
        const menuItems = document.querySelectorAll('#franchise-sticky-menu .menu-item');
        const quoteSection = document.getElementById('franchise-quotes');
        if (menuItems && quoteSection) {
          const quoteSectionRect = quoteSection.getBoundingClientRect();
          menuItems.forEach((item, i) => {
            if (
              item.getBoundingClientRect().top > quoteSectionRect.top &&
              item.getBoundingClientRect().top < quoteSectionRect.bottom - 40
            ) {
              item.classList.add('in-view');
            } else {
              item.classList.remove('in-view');
            }
          });
        }
      };
      window.addEventListener('scroll', checkMenuScroll);
      return function () {
        window.removeEventListener('scroll', checkMenuScroll);
      };
    }
  }, []);
  return (
    <nav
      id="franchise-sticky-menu"
      className="franchise-sticky-menu stickySidebar"
      aria-label="in-page"
    >
      <ul>
        <li className={whyCmlInView && !quotesInView ? 'menu-item active' : 'menu-item'}>
          <a href="#why-cml-ref" onClick={handleMenuItemClick}>
            <BodyTextAlt>Why Cml</BodyTextAlt>
          </a>
        </li>
        <li className={quotesInView && !asSeenInView ? 'menu-item active' : 'menu-item'}>
          <a href="#quotes-ref" onClick={handleMenuItemClick}>
            <BodyTextAlt>Quotes</BodyTextAlt>
          </a>
        </li>
        <li className={asSeenInView && !supportInView ? 'menu-item active' : 'menu-item'}>
          <a href="#as-seen-in-ref" onClick={handleMenuItemClick}>
            <BodyTextAlt>As Seen In</BodyTextAlt>
          </a>
        </li>
        <li className={supportInView && !stepsInView ? 'menu-item active' : 'menu-item'}>
          <a href="#support-ref" onClick={handleMenuItemClick}>
            <BodyTextAlt>Support</BodyTextAlt>
          </a>
        </li>
        <li className={stepsInView && !joinFormInView ? 'menu-item active' : 'menu-item'}>
          <a href="#steps-ref" onClick={handleMenuItemClick}>
            <BodyTextAlt>12 Steps</BodyTextAlt>
          </a>
        </li>
        <li className={joinFormInView ? 'menu-item active last' : 'menu-item last'}>
          <a href="#join-form-ref" onClick={handleMenuItemClick}>
            <BodyTextAlt>Form</BodyTextAlt>
          </a>
        </li>
        {/* <div className="menu-item filler" /> */}
      </ul>
    </nav>
  );
};

export default StickyMenu;
