import styled from 'styled-components';
import { HeadingLarge, BodyText } from 'components/.base/headings';
import { breakpoint } from 'constants/Breakpoint';

const radius = 15;
const borderWidth = 2;

export const DotWrapper = styled.span`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: dotted 2px #889b9d;
  position: absolute;
  border-radius: 50%;
  ${({ pos }) =>
    pos === 'left'
      ? `
        top: 40px;
        left: 9px;
        transform: translate(-100%, -75%);
        @media ${breakpoint.lg} {
            top: 0;
            left: 0;
        }
    `
      : ''}
  ${({ pos }) =>
    pos === 'right'
      ? `
        display: none;
        right: 0;
        top: 35px;
        transform: translate(50%, -20%);
        @media ${breakpoint.lg} {
            display: flex;
        }
    `
      : ''}
`;

export const Dot = styled.span`
  width: 10px;
  height: 10px;
  border: solid 2px #ffffff;
  background-color: #bbcbcc;
  display: block;
  border-radius: 50%;
`;

export const Grid = styled.ul`
  display: grid;
  grid-column-gap: 5rem;
  justify-content: center;
  grid-template-columns: 1fr;
  padding: 0 20px;
  @media ${breakpoint.lg} {
    border-left: 0;
    padding: 0 2.5rem;
    grid-template-columns: minmax(min-content, 590px) minmax(min-content, 400px);
    grid-template-rows: repeat(3, 1fr auto);
  }
`;

export const GridItem = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 15px;
  margin: -0.525px 0;
  position: relative;
  padding: 15px 0 15px 50px;
  border-left: ${borderWidth}px dotted;
  @media ${breakpoint.maxMd} {
    &:first-child,
    &:last-child {
      ${Dot} {
        &:after {
          content: '';
          position: absolute;
          left: -1px;
          height: 50px;
          width: ${radius}px;
          background-color: #fff;
          z-index: 1;
        }
      }
    }
    &:first-child {
      ${Dot} {
        &:after {
          bottom: 18px;
        }
      }
    }
    &:last-child {
      ${Dot} {
        &:after {
          top: 18px;
        }
      }
    }
  }
  @media ${breakpoint.lg} {
    padding: 25px;
    border-left: 0;
  }
  ${HeadingLarge} {
    margin-left: -${radius}px;
  }
  ${BodyText} {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: -0.2px;
    @media ${breakpoint.maxLg} {
      margin-bottom: 0;
    }
    @media ${breakpoint.lg} {
      max-width: 330px;
    }
  }
  @media ${breakpoint.lg} {
    &:nth-child(odd) {
      position: relative;
      border-top: ${borderWidth}px dotted;
      border-right: ${borderWidth}px dotted;
      border-color: #889b9d;
      border-top-right-radius: ${radius}px;
      border-bottom-right-radius: ${radius}px;
      margin-bottom: 25px;
      margin-left: ${radius - 4}px;
      padding-left: 0;
      &:before {
        content: '';
        height: 25px;
        position: absolute;
        bottom: -23px;
        left: -13px;
        right: 6px;
        border-left: ${borderWidth}px dotted;
        border-top-left-radius: ${radius}px;
        border-bottom-left-radius: ${radius}px;
        padding-right: 0;
        border-top: ${borderWidth}px dotted;
        border-color: #889b9d;
      }
    }

    &:first-child {
      border-top-left-radius: 0;
    }

    &:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:nth-last-child(2) {
      &:before {
        content: none;
      }
      &:after {
        content: '';
        position: absolute;
        right: -${borderWidth * 2}px;
        top: 52px;
        height: calc(50% + 30px);
        width: ${radius}px;
        background-color: #fff;
        z-index: 1;
      }
    }
  }
`;
