import React, { Fragment, useRef, useState } from 'react';
import { Formik } from 'formik';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import styled, { css } from 'styled-components';
import Image from 'gatsby-image';
import 'date-fns';
import { useMediaQuery } from 'react-responsive';
import ReCAPTCHA from 'react-google-recaptcha';

import './styles.scss';
import { breakpoint, size } from '../../../constants/Breakpoint';
import { appendFormData, stateLists, getImage } from '~/helpers';
import { CenteredContainer, Grid, Row, Col, FlexBox } from '~/components/.base/containers';
import { HeadingLarge, Heading, BodyText, BodyTextAlt } from '~/components/.base/headings';
import {
  FormControl,
  FormControlSelect,
  FormSuccess,
  FormError,
  InputSelect,
} from '~/components/.base/inputs';
import { RedButton } from '~/components/.base/buttons';
import ArrowDown from '~/images/icons/arrow_normal_down.svg';

const CntWrapper = styled.div`
  width: 700px;
  max-width: 100%;
  margin: 0 auto;
  .label {
    margin-bottom: 15px;
  }
`;
const MainCol = styled(Col)`
  padding: 60px 20px;
  @media ${breakpoint.lg} {
    padding: 5rem 2.5rem;
  }
`;
const RadioText = styled.span`
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: -0.2px;
  font-family: TimesNewRomanPSMT;
  position: relative;
`;

const RadioControl = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  border-radius: 30px;
  box-shadow: inset 0 0 0 1px #c4cbcc;
  margin-bottom: 5px;
  input[type='radio'] {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    appearance: none;
    border: none;
    outline: none;
    border-color: transparent;
    border-style: solid;
    transition: opacity 0.25s ease-in-out;
    cursor: pointer;
    &:checked {
      background-color: #d4212c;
      + ${RadioText} {
        color: #fff;
      }
    }
    &:focus {
      &:not(:disabled) {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 0 3px rgba(212, 33, 44, 0.75);
      }
    }
  }
  label {
    position: relative;
    height: 40px;
    padding: 10px 20px;
    margin: 0;
    cursor: pointer;
    &:first-child {
      input[type='radio'] {
        border-radius: 30px 0 0 30px;
        border-width: 1px 0 1px 1px;
      }
    }
    &:last-child {
      input[type='radio'] {
        border-radius: 0 30px 30px 0;
        border-width: 1px 1px 1px 0;
      }
    }
    &:not(:first-child):not(:last-child) {
      input[type='radio'] {
        border-radius: 0;
        border-width: 1px 0;
      }
    }
  }
`;

const Hr = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e1e4e4;
  margin: 35px 0 55px;
`;

const initialErrors = {
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  city: null,
  state: null,
  zip: null,
  budget: null,
  occupation: null,
  businessEmployment: null,
  marketInterest: null,
  preference: null,
  beenFranchise: null,
  franchiseOwnRent: null,
  familyInvolve: null,
  soleIncomeSource: null,
  timetable: null,
  whyCmlSuccess: null,
  hearAboutUs: null,
  comments: null,
};
const JoinForm = ({ showStickyMenu, sectionRef, sectionData = {}, pageImages }) => {
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState('');
  const [errors, setErrors] = useState(initialErrors);
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    city: '',
    state: '',
    zip: '',
    budget: '',
    occupation: '',
    businessEmployment: '',
    marketInterest: 'Single',
    preference: 'Restaurant',
    beenFranchise: 'Yes',
    franchiseOwnRent: 'Own',
    familyInvolve: 'Yes',
    soleIncomeSource: 'Yes',
    timetable: '1',
    whyCmlSuccess: '',
    hearAboutUs: '',
    comments: '',
  });
  const [isReCaptchaValid, setIsReCaptchaValid] = useState(false);
  const [captchaToken, setCaptchaToken] = useState();

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const zipRef = useRef(null);
  const budgetRef = useRef(null);
  const occupationRef = useRef(null);
  const businessEmploymentRef = useRef(null);
  const whyCmlSuccessRef = useRef(null);
  const hearAboutUsRef = useRef(null);
  const commentsRef = useRef(null);

  const handleReCaptchaChange = value => {
    setIsReCaptchaValid(!!value);
    setCaptchaToken(value)
  };

  const submitForm = async (values, { setSubmitting, resetForm }) => {
    setFormError('');
    setFormSuccess(false);
    setSubmitting(true);
    const formData = appendFormData(values);
    let verifyCaptchaTokenRes = null;

    try {
      verifyCaptchaTokenRes = await axios.post(`${process.env.GATSBY_SERVER_API_URL}/api/forms/verify-captcha`, {
        token: captchaToken
      });
    } catch (e) {
      setSubmitting(false);
      setFormError(e?.message);
    }

    if (!verifyCaptchaTokenRes?.data?.success) {
      setSubmitting(false);
      setFormError('Please check the recaptcha and try again');

      return;
    }

    axios
      .post(`${process.env.GATSBY_SERVER_API_URL}/api/forms/franchise`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
      })
      .then(res => {
        if (window && window.ga) {
          window.ga('send', 'event', 'form', 'submission', 'Franchise Form Submission', '1');
        }
        setSubmitting(false);
        setInitialValues(vals => Object.assign(vals));
        setFormSuccess(true);
      })
      .then(() => {
        resetForm({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          city: '',
          state: '',
          zip: '',
          budget: '',
          occupation: '',
          businessEmployment: '',
          marketInterest: 'Single',
          preference: 'Restaurant',
          beenFranchise: 'Yes',
          franchiseOwnRent: 'Own',
          familyInvolve: 'Yes',
          soleIncomeSource: 'Yes',
          timetable: '1',
          whyCmlSuccess: '',
          hearAboutUs: '',
          comments: '',
        });
      })
      .catch(err => {
        setSubmitting(false);
        setFormError(err.message);
      });
  };
  return (
    <Row ref={sectionRef}>
      <Col
        size={1}
        className="stickySidebar"
        css={`
          display: none;
          ${showStickyMenu
            ? `
          @media ${breakpoint.lg} {
              display: block;                    
          }
      `
            : ``}
        `}
      />
      <MainCol size={4}>
        <CntWrapper>
          <CenteredContainer
            css={css`
              margin-bottom: 90px;
            `}
          >
            <HeadingLarge
              as="h2"
              css={css`
                text-align: center;
                margin-bottom: 60px;
              `}
            >
              <span>
                Join{' '}
                <span className="red">
                  the <br />
                  family!
                </span>
              </span>
            </HeadingLarge>
            <div
              css={css`
                width: 400px;
                max-width: 100%;
                border-radius: 40px;
                overflow: hidden;
              `}
            >
              <Image fluid={getImage(pageImages, 'join-banner')} alt="" className="top-border" />
            </div>
          </CenteredContainer>
          <div id="joinNow">
            <Heading
              as="h3"
              css={css`
                margin-bottom: 25px;
              `}
            >
              <span className="red">01.</span> Tell us about yourself
            </Heading>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validateOnChange={false}
              validateOnBlur={false}
              validate={values => {
                const errs = {};
                if (!values.hearAboutUs) {
                  errs.hearAboutUs = 'Required';
                  if (hearAboutUsRef?.current) hearAboutUsRef.current.focus();
                }
                if (!values.whyCmlSuccess) {
                  errs.whyCmlSuccess = 'Required';
                  if (whyCmlSuccessRef?.current) whyCmlSuccessRef.current.focus();
                }
                if (!values.businessEmployment) {
                  errs.businessEmployment = 'Required';
                  if (businessEmploymentRef?.current) businessEmploymentRef.current.focus();
                }
                if (!values.occupation) {
                  errs.occupation = 'Required';
                  if (occupationRef?.current) occupationRef.current.focus();
                }
                if (!values.budget) {
                  errs.budget = 'Required';
                  if (budgetRef?.current) budgetRef.current.focus();
                }
                if (!values.zip) {
                  errs.zip = 'Required';
                  if (zipRef?.current) zipRef.current.focus();
                }
                if (!values.state) {
                  errs.state = 'Required';
                  if (stateRef?.current) stateRef.current.focus();
                }
                if (!values.city) {
                  errs.city = 'Required';
                  if (cityRef?.current) cityRef.current.focus();
                }
                if (!values.phone) {
                  errs.phone = 'Required';
                  if (phoneRef?.current) phoneRef.current.focus();
                }
                if (values.email === '') {
                  errs.email = 'Required';
                  if (emailRef?.current) emailRef.current.focus();
                } else if (
                  values.email &&
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errs.email = 'Invalid email address';
                  if (emailRef?.current) emailRef.current.focus();
                }
                if (!values.lastName) {
                  errs.lastName = 'Required';
                  if (lastNameRef?.current) lastNameRef.current.focus();
                }
                if (!values.firstName) {
                  errs.firstName = 'Required';
                  if (firstNameRef?.current) firstNameRef.current.focus();
                }
                setErrors(errs);
                return errs;
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errs,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form>
                  <BodyTextAlt md="0 0 2rem">* indicates a required field</BodyTextAlt>
                  <Grid
                    css={css`
                      ${Row} {
                        flex-direction: column;
                        @media ${breakpoint.sm} {
                          flex-direction: row;
                        }
                      }
                      .label {
                        width: calc(100% - 2.5rem);
                      }
                    `}
                  >
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="firstName" className="label">
                            First Name
                            <span className="red">*</span>
                          </label>
                          <input
                            id="firstName"
                            name="firstName"
                            className={`field${!!errors.firstName ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            ref={firstNameRef}
                            aria-describedby="firstNameError"
                            autoComplete="given-name"
                          />
                          <div id="firstNameError" className="error">
                            {errors.firstName}
                          </div>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="lastName" className="label">
                            Last Name
                            <span className="red">*</span>
                          </label>
                          <input
                            id="lastName"
                            name="lastName"
                            className={`field${!!errors.lastName ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                            ref={lastNameRef}
                            aria-describedby="lastNameError"
                            autoComplete="family-name"
                          />
                          <div id="lastNameError" className="error">
                            {errors.lastName}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="email" className="label">
                            Email
                            <span className="red">*</span>
                          </label>
                          <input
                            id="email"
                            name="email"
                            className={`field${!!errors.email ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            ref={emailRef}
                            aria-describedby="emailError"
                            autoComplete="email"
                          />
                          <div id="emailError" className="error">
                            {errors.email}
                          </div>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="phone" className="label">
                            Phone
                            <span className="red">*</span>
                          </label>
                          <input
                            id="phone"
                            name="phone"
                            className={`field${!!errors.phone ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                            ref={phoneRef}
                            aria-describedby="phoneError"
                            autoComplete="tel"
                          />
                          <div id="phoneError" className="error">
                            {errors.phone}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="city" className="label">
                            City
                            <span className="red">*</span>
                          </label>
                          <input
                            id="city"
                            name="city"
                            className={`field${!!errors.city ? ' is-invalid' : ''}`}
                            placeholder="Enter your city here"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city}
                            ref={cityRef}
                            aria-describedby="cityError"
                            autoComplete="address-level3"
                          />
                          <div id="cityError" className="error">
                            {errors.city}
                          </div>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControlSelect w="calc(100% - 2rem)">
                          <label htmlFor="state" className="label">
                            State
                            <span className="red">*</span>
                          </label>
                          <select
                            id="state"
                            name="state"
                            className={`field${!!errors.state ? ' is-invalid' : ''}`}
                            placeholder="Country"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.state}
                            ref={stateRef}
                            aria-describedby="stateError"
                            autoComplete="address-level1"
                          >
                            <option value="">Select State</option>
                            {Object.entries(stateLists['US']).map((state, i) => (
                              <option key={i} value={state[0]}>
                                {state[1]}
                              </option>
                            ))}
                          </select>
                          <ArrowDown
                            style={{
                              pointerEvents: 'none',
                            }}
                          />
                          <div id="stateError" className="error">
                            {errors.state}
                          </div>
                        </FormControlSelect>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="zip" className="label">
                            Zip
                            <span className="red">*</span>
                          </label>
                          <input
                            id="zip"
                            name="zip"
                            className={`field${!!errors.zip ? ' is-invalid' : ''}`}
                            placeholder="eg. 90210"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.zip}
                            ref={zipRef}
                            aria-describedby="zipError"
                            autoComplete="postal-code"
                          />
                          <div id="zipError" className="error">
                            {errors.zip}
                          </div>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControl>
                          <InputSelect w="100%">
                            <label htmlFor="budget" className="label">
                              Budget ($)
                              <span className="red">*</span>
                            </label>
                            <select
                              id="budget"
                              style={{
                                width: 'calc(100% - 2rem)',
                                height: 60,
                                borderRadius: 30,
                              }}
                              name="budget"
                              className={`field${!!errors.budget ? ' is-invalid' : ''}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.budget}
                              ref={budgetRef}
                              aria-describedby="budgetError"
                            >
                              <option value="$150,000-$199,999">$150,000-$199,999</option>
                              <option value="$200,000-$299,999">$200,000-$299,999</option>
                              <option value="$300,000-$499,999">$300,000-$499,999</option>
                              <option value="More than $500,000">More than $500,000</option>
                            </select>
                            <ArrowDown
                              style={{
                                right: 'calc(2rem + 15px)',
                                top: '51.5px',
                                pointerEvents: 'none',
                              }}
                            />
                          </InputSelect>
                          <div id="budgetError" className="error">
                            {errors.budget}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="occupation" className="label">
                            Occupation
                            <span className="red">*</span>
                          </label>
                          <input
                            id="occupation"
                            name="occupation"
                            className={`field${!!errors.occupation ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.occupation}
                            ref={occupationRef}
                            aria-describedby="occupationError"
                          />
                          <div id="occupationError" className="error">
                            {errors.occupation}
                          </div>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="businessEmployment" className="label">
                            Present business or employment
                            <span className="red">*</span>
                          </label>
                          <input
                            id="businessEmployment"
                            name="businessEmployment"
                            className={`field${!!errors.businessEmployment ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.businessEmployment}
                            ref={businessEmploymentRef}
                            aria-describedby="businessEmploymentError"
                          />
                          <div id="businessEmploymentError" className="error">
                            {errors.businessEmployment}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    <Hr />
                    <Heading
                      as="h3"
                      css={css`
                        margin-bottom: 25px;
                      `}
                    >
                      <span className="red">02.</span> Tell us about your market
                    </Heading>
                    <Row>
                      <Col size={1}>
                        <FormControl as="fieldset">
                          <legend className="label">
                            Interested in
                            <span className="red">*</span>
                          </legend>
                          <RadioControl>
                            <label htmlFor="marketInterestSingle">
                              <input
                                id="marketInterestSingle"
                                name="marketInterest"
                                type="radio"
                                onChange={handleChange}
                                value="Single"
                                defaultChecked
                              />
                              <RadioText>Single</RadioText>
                            </label>
                            <label htmlFor="marketInterestMultiple">
                              <input
                                id="marketInterestMultiple"
                                name="marketInterest"
                                type="radio"
                                onChange={handleChange}
                                value="Multiple"
                              />
                              <RadioText>Multiple</RadioText>
                            </label>
                          </RadioControl>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControl as="fieldset">
                          <legend className="label">
                            Franchise preference
                            <span className="red">*</span>
                          </legend>
                          <RadioControl>
                            <label htmlFor="preferenceRestaurant">
                              <input
                                id="preferenceRestaurant"
                                name="preference"
                                type="radio"
                                onChange={handleChange}
                                value="Restaurant"
                                defaultChecked
                              />
                              <RadioText>Restaurant</RadioText>
                            </label>
                            <label htmlFor="preferenceFoodTruck">
                              <input
                                id="preferenceFoodTruck"
                                name="preference"
                                type="radio"
                                onChange={handleChange}
                                value="Food truck"
                              />
                              <RadioText>Food truck</RadioText>
                            </label>
                          </RadioControl>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl as="fieldset">
                          <legend className="label">
                            Have you ever been a franchisee of another company?
                            <span className="red">*</span>
                          </legend>
                          <RadioControl>
                            <label htmlFor="beenFranchiseYes">
                              <input
                                id="beenFranchiseYes"
                                name="beenFranchise"
                                type="radio"
                                onChange={handleChange}
                                value="Yes"
                                defaultChecked
                              />
                              <RadioText>Yes</RadioText>
                            </label>
                            <label htmlFor="beenFranchiseNo">
                              <input
                                id="beenFranchiseNo"
                                name="beenFranchise"
                                type="radio"
                                onChange={handleChange}
                                value="No"
                              />
                              <RadioText>No</RadioText>
                            </label>
                          </RadioControl>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControl as="fieldset">
                          <legend className="label">
                            Do You Presently Own or Rent?
                            <span className="red">*</span>
                          </legend>
                          <RadioControl>
                            <label htmlFor="franchiseOwnRentOwn">
                              <input
                                id="franchiseOwnRentOwn"
                                name="franchiseOwnRent"
                                type="radio"
                                onChange={handleChange}
                                value="Own"
                                defaultChecked
                              />
                              <RadioText>Own</RadioText>
                            </label>
                            <label htmlFor="franchiseOwnRentRent">
                              <input
                                id="franchiseOwnRentRent"
                                name="franchiseOwnRent"
                                type="radio"
                                onChange={handleChange}
                                value="Rent"
                              />
                              <RadioText>Rent</RadioText>
                            </label>
                          </RadioControl>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl as="fieldset">
                          <legend className="label">
                            Will other family members be involved in the franchise?
                            <span className="red">*</span>
                          </legend>
                          <RadioControl>
                            <label htmlFor="familyInvolveYes">
                              <input
                                id="familyInvolveYes"
                                name="familyInvolve"
                                type="radio"
                                onChange={handleChange}
                                value="Yes"
                                defaultChecked
                              />
                              <RadioText>Yes</RadioText>
                            </label>
                            <label htmlFor="familyInvolveNo">
                              <input
                                id="familyInvolveNo"
                                name="familyInvolve"
                                type="radio"
                                onChange={handleChange}
                                value="No"
                              />
                              <RadioText>No</RadioText>
                            </label>
                          </RadioControl>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControl as="fieldset">
                          <legend className="label">
                            Will this be your sole source of income?
                            <span className="red">*</span>
                          </legend>
                          <RadioControl>
                            <label htmlFor="soleIncomeSourceYes">
                              <input
                                id="soleIncomeSourceYes"
                                name="soleIncomeSource"
                                type="radio"
                                onChange={handleChange}
                                value="Yes"
                                defaultChecked
                              />
                              <RadioText>Yes</RadioText>
                            </label>
                            <label htmlFor="soleIncomeSourceNo">
                              <input
                                id="soleIncomeSourceNo"
                                name="soleIncomeSource"
                                type="radio"
                                onChange={handleChange}
                                value="No"
                              />
                              <RadioText>No</RadioText>
                            </label>
                          </RadioControl>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl as="fieldset">
                          <legend className="label">
                            What’s your timetable in months?
                            <span className="red">*</span>
                          </legend>
                          <RadioControl>
                            <label htmlFor="timetable1">
                              <input
                                id="timetable1"
                                name="timetable"
                                type="radio"
                                onChange={handleChange}
                                value="1"
                                defaultChecked
                              />
                              <RadioText>1</RadioText>
                            </label>
                            <label htmlFor="timetable3">
                              <input
                                id="timetable3"
                                name="timetable"
                                type="radio"
                                onChange={handleChange}
                                value="3"
                              />
                              <RadioText>3</RadioText>
                            </label>
                            <label htmlFor="timetable6">
                              <input
                                id="timetable6"
                                name="timetable"
                                type="radio"
                                onChange={handleChange}
                                value="6"
                              />
                              <RadioText>6</RadioText>
                            </label>
                          </RadioControl>
                        </FormControl>
                      </Col>
                    </Row>
                    <FormControl>
                      <label htmlFor="whyCmlSuccess" className="label">
                        Please explain your understanding of your local food truck market and why
                        you believe Cousins Maine Lobster food truck offering would be a success
                        there:
                        <span className="red">*</span>
                      </label>
                      <input
                        id="whyCmlSuccess"
                        name="whyCmlSuccess"
                        type="textarea"
                        className={`field${!!errors.whyCmlSuccess ? ' is-invalid' : ''}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.whyCmlSuccess}
                        placeholder="Tell us here"
                        ref={whyCmlSuccessRef}
                        aria-describedby="whyCmlSuccessError"
                      />
                      <div id="whyCmlSuccessError" className="error">
                        {errors.whyCmlSuccess}
                      </div>
                    </FormControl>
                    <Hr />
                    <Heading
                      as="h3"
                      css={css`
                        margin-bottom: 25px;
                      `}
                    >
                      <span className="red">03.</span> Extra credit
                    </Heading>
                    <FormControl>
                      <label htmlFor="hearAboutUs" className="label">
                        How did you hear about Cousins Maine Lobster franchising?
                        <span className="red">*</span>
                      </label>
                      <input
                        id="hearAboutUs"
                        name="hearAboutUs"
                        type="textarea"
                        className={`field${!!errors.hearAboutUs ? ' is-invalid' : ''}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.hearAboutUs}
                        placeholder="Tell us here"
                        ref={hearAboutUsRef}
                        aria-describedby="hearAboutUsError"
                      />
                      <div id="hearAboutUsError" className="error">
                        {errors.hearAboutUs}
                      </div>
                    </FormControl>
                    <FormControl>
                      <label htmlFor="comments" className="label">
                        Comments
                      </label>
                      <input
                        id="comments"
                        name="comments"
                        type="textarea"
                        className={`field${!!errors.comments ? ' is-invalid' : ''}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.comments}
                        placeholder="Write here"
                        ref={commentsRef}
                        aria-describedby="commentsError"
                      />
                      <div id="commentsError" className="error">
                        {errors.comments}
                      </div>
                    </FormControl>
                    {formSuccess ? (
                      <FormSuccess role="alert">
                        <BodyTextAlt>Thank you! We will get back to you shortly.</BodyTextAlt>
                      </FormSuccess>
                    ) : null}
                    {formError.length ? (
                      <FormError>
                        <BodyTextAlt>{formError}</BodyTextAlt>
                      </FormError>
                    ) : null}
                    <FlexBox justify="center">
                      <ReCAPTCHA
                        className="recaptcha"
                        sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
                        onChange={handleReCaptchaChange}
                      />
                    </FlexBox>
                    <FlexBox justify="center">
                      <RedButton
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!isReCaptchaValid || (isReCaptchaValid && isSubmitting)}
                        h="50px"
                        pd="0 35px"
                      >
                        {isSubmitting ? (
                          <Fragment>
                            <Loader type="TailSpin" color="#152622" height={16} width={16} />
                            Loading...
                          </Fragment>
                        ) : (
                          'Submit'
                        )}
                      </RedButton>
                    </FlexBox>
                    <BodyText
                      css={css`
                        line-height: 1.38;
                        letter-spacing: -0.2px;
                        margin-top: 60px;
                        margin-bottom: 150px;
                      `}
                    >
                      {sectionData?.formDisclaimerText?.formDisclaimerText}
                    </BodyText>
                  </Grid>
                </form>
              )}
            </Formik>
          </div>
        </CntWrapper>
      </MainCol>
    </Row>
  );
};

export default JoinForm;
