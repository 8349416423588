import React from 'react';
import { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { BackgroundDivBgImage, BorderedImage, Row, Col } from 'components/.base/containers';
import { HeadingLarge, BodyText } from 'components/.base/headings';
import { breakpoint, size } from 'constants/Breakpoint';
import { WoodenBorder } from 'components/.base/border';
import { Dot, DotWrapper, Grid, GridItem } from './Steps.styled';

const Steps = ({ bgImages, showStickyMenu, sectionRef, sectionData }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });
  return (
    <Row
      css={css`
        position: relative;
        padding: 60px 0;
        @media ${breakpoint.lg} {
          padding: 120px 0 60px;
        }
      `}
      ref={sectionRef}
    >
      <div
        css={css`
          display: none;
          @media ${breakpoint.lg} {
            display: block;
          }
        `}
      >
        <BorderedImage
          top="-120px"
          topMobile="-106px"
          bottom="375px"
          topBottomBorder="calc(100vh - 130px)"
          topBottomBorderMobile="calc(100vh - 110px)"
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
          }}
        >
          <WoodenBorder top="-100px" />
          <WoodenBorder top="876px" />
          <BackgroundDivBgImage
            w="100%"
            h="976px"
            heightMobile="100vh"
            top="-100px"
            topMobile="-100px"
            color="#edf2f2"
            style={{ zIndex: '-2' }}
          />
        </BorderedImage>
      </div>
      {showStickyMenu && !isMobile && <Col size={1} className="stickySidebar" />}
      <Col size={4}>
        <HeadingLarge
          as="h2"
          css={css`
            margin-bottom: 75px;
            padding: 0 2.5rem;
            text-align: center;
          `}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: sectionData.heading,
            }}
          ></span>
        </HeadingLarge>
        <Grid>
          {sectionData.franchisePage12StepsSectionStep.map((step, i) => (
            <GridItem key={step.title.title}>
              <HeadingLarge as="p">
                <span className="red">{i <= 8 ? `0${i + 1}` : i + 1}</span>
              </HeadingLarge>
              <BodyText>{step.title.title}</BodyText>
              <DotWrapper
                pos="left"
                css={css`
                  ${i === 0
                    ? `
                                    transform: translate(-100%, -50%);
                                `
                    : ''}
                  ${!(
                    (i + 1) % 2 === 1 && i + 2 < sectionData.franchisePage12StepsSectionStep.length
                  )
                    ? `
                                    @media ${breakpoint.lg} {
                                        display: none;
                                    }
                                `
                    : ''}
                `}
              >
                <Dot />
              </DotWrapper>
              {(i + 1) % 2 === 1 && !isMobile ? (
                <DotWrapper pos="right">
                  <Dot />
                </DotWrapper>
              ) : (
                ''
              )}
            </GridItem>
          ))}
        </Grid>
      </Col>
    </Row>
  );
};

export default Steps;
