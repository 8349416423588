import styled from 'styled-components';
import { Subheading } from 'components/.base/headings';

export const CollapsibleHeader = styled.h3`
    margin: 0;
`;
export const CollapsibleButton = styled(Subheading)`
    border: none;
    outline: none;
    background: none;
    margin: 0;
    padding: 0;
    width: 100%;
    color: #d4212c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    text-align: left;
`;
export const CollapsibleItem = styled.div``;
export const CollapsibleIcon = styled.span`
    position: relative;
    width: 11px;
    height: 11px;
    margin-left: 15px;

    &:before,
    &:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        background-color: currentColor;
        transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    }

    &:before {
        width: 11px;
        height: 1px;
        opacity: 1;
    }

    &:after {
        width: 1px;
        height: 11px;
    }

    ${CollapsibleButton}[aria-expanded=true] &:before,
${CollapsibleButton}[aria-expanded=true] &:after {
        transform: translate(-50%, -50%) rotate(90deg);
    }

    ${CollapsibleButton}[aria-expanded=true] &:before {
        opacity: 0;
    }
`;
export const CollapsibleItemWrapper = styled.div`
    border-bottom: 1px solid #e2e4e4;
`;
export const CollapsibleInner = styled.div`
    max-width: 490px;
    height: 0;
    overflow: hidden;
    transition: height 0.25s ease-in-out, visibility 0s ease-in-out 0.25s;
    &[aria-hidden='false'] {
        visibility: visible;
        transition: height 0.25s ease-in-out;
    }
`;
