import React from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import Image from 'gatsby-image';

import { Row, Col } from '../../.base/containers';
import { HeadingLarge, Subheading, BodyText } from '../../.base/headings';
import { breakpoint, size } from '../../../constants/Breakpoint';

const ImgWrap = styled.div`
  width: 100%;
  border: 2px solid #fff;
  overflow: hidden;
`;

const MainImgWrap = styled(ImgWrap)`
  border-radius: 0 0 30px 30px;
  @media ${breakpoint.lg} {
    border-radius: 30px 0 30px 30px;
  }
`;

const SecondaryImgWrap = styled(ImgWrap)`
  &:first-child {
    border-radius: 30px 30px 0 0;
  }
`;

const Support = ({ showStickyMenu, sectionRef, sectionData }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });
  return (
    <Row
      css={css`
        padding: 60px 0;
      `}
      ref={sectionRef}
    >
      {showStickyMenu && !isMobile && <Col size={1} className="stickySidebar" />}
      <Col size={4}>
        <Row
          css={css`
            flex-direction: column;
            @media ${breakpoint.lg} {
              flex-direction: row;
            }
          `}
        >
          <Col
            size={4}
            css={css`
              margin-bottom: 25px;
              padding: 0 20px;
              @media ${breakpoint.lg} {
                padding: 0 2.5rem;
              }
            `}
          >
            <HeadingLarge
              as="h2"
              css={css`
                margin-bottom: 35px;
              `}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: sectionData.heading,
                }}
              ></span>
            </HeadingLarge>
            <Subheading
              css={css`
                margin-bottom: 30px;
              `}
            >
              {sectionData.subheading}
            </Subheading>
            <BodyText
              css={css`
                line-height: 1.5;
                white-space: break-spaces;
              `}
            >
              {sectionData.description.description}
            </BodyText>
          </Col>
          <Col
            size={2}
            css={css`
              padding: 0 20px;
              @media ${breakpoint.lg} {
                padding: 0 2.5rem;
              }
            `}
          >
            {sectionData.secondaryImages.map((image, i) => (
              <SecondaryImgWrap key={i} data-src={image.fluid.src}>
                <Image fluid={image.fluid} alt={image.description} />
              </SecondaryImgWrap>
            ))}
          </Col>
        </Row>
        <div
          css={css`
            padding: 0 20px;
            @media ${breakpoint.lg} {
              padding: 0 2.5rem;
            }
          `}
        >
          <MainImgWrap>
            <Image fluid={sectionData.mainImage.fluid} alt={sectionData.mainImage.description} />
          </MainImgWrap>
        </div>
      </Col>
    </Row>
  );
};

export default Support;
