import React, { Fragment, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { css } from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';

import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';
import Hero from '~/components/.base/hero';
import { CenteredContainer, Container } from '../components/.base/containers';
import { HeadingLarge, BodyText } from '~/components/.base/headings';
import { RedButton } from '../components/.base/buttons';
import MailingListPopup from '~/components/JoinMailingList/MailingListPopup';
import {
  WhyCml,
  Quotes,
  AsSeenIn,
  Support,
  Steps,
  JoinForm,
  Faq,
} from '../components/FranchisePage';
import { StickyMenu } from '../components/FranchisePage';
import { breakpoint, size } from '../constants/Breakpoint';

const Franchise = props => {
  const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });
  const { data, location } = { ...props };
  const [bgImageData, setBgImageData] = useState([]);
  const [pageImageData, setPageImageData] = useState([]);

  useEffect(() => {
    if (data && data.bgImages) {
      setBgImageData(data.bgImages.edges);
    }
    if (data && data.pageImages) {
      setPageImageData(data.pageImages.edges);
    }
  }, [data]);

  const showStickyMenu = true;

  const pageHero = props.data.contentfulPage.contentModules.find(
    m => m.__typename === 'ContentfulPageHero'
  );

  const whyCmlSection = props.data.contentfulPage.contentModules.find(
    m => m.__typename === 'ContentfulFranchisePageWhyCmlSection'
  );

  const quotesSection = props.data.contentfulPage.contentModules.find(
    m => m.__typename === 'ContentfulFranchisePageQuotesSection'
  );

  const logoSection = props.data.contentfulPage.contentModules.find(
    m => m.__typename === 'ContentfulLogoSection'
  );

  const supportSection = props.data.contentfulPage.contentModules.find(
    m => m.__typename === 'ContentfulFranchisePageSupportSection'
  );

  const stepsSection = props.data.contentfulPage.contentModules.find(
    m => m.__typename === 'ContentfulFranchisePage12StepsSection'
  );

  const faqSection = props.data.contentfulPage.contentModules.find(
    m => m.__typename === 'ContentfulFranchisePageFaqSection'
  );

  const joinFormSection = props.data.contentfulPage.contentModules.find(
    m => m.__typename === 'ContentfulFranchisePageJoinFormSection'
  );

  const { ref: whyCmlRef, inView: whyCmlInView } = useInView({
    /* Optional options */
    threshold: 0.1,
  });
  const { ref: quotesRef, inView: quotesInView } = useInView({
    /* Optional options */
    threshold: 0.5,
  });
  const { ref: asSeenInRef, inView: asSeenInView } = useInView({
    /* Optional options */
    threshold: 0.5,
  });
  const { ref: supportRef, inView: supportInView } = useInView({
    /* Optional options */
    threshold: 0.5,
  });
  const { ref: stepsRef, inView: stepsInView } = useInView({
    /* Optional options */
    threshold: 0.3,
  });
  const { ref: joinFormRef, inView: joinFormInView } = useInView({
    /* Optional options */
    threshold: 0.1,
  });

  return (
    <Fragment>
      <MailingListPopup />
      <SEO title="Franchise" />
      <Criteo />
      <DeriveHeaderClasses location={location} />
      <div className="franchise">
        <Hero size="full" imageFluid={pageHero.backgroundImage.fluid} alt="">
          <Container
            css={css`
              text-align: center;
            `}
            className="cont"
          >
            <CenteredContainer>
              <HeadingLarge
                css={css`
                  text-align: center;
                  color: #fff;
                  margin-bottom: 20px;
                `}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: pageHero.heading,
                  }}
                />
              </HeadingLarge>
              <BodyText
                as="div"
                css={css`
                  color: #fff;
                  margin-bottom: 30px;
                `}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageHero.description.description,
                  }}
                />
              </BodyText>
              {pageHero.buttonText && pageHero.buttonLink ? (
                pageHero.buttonLink.startsWith('#') ? (
                  <div
                    css={css`
                      a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: ${props => props.w};
                        margin: ${props => props.md};
                        border-radius: 30px;
                        height: 40px;
                        height: ${props => props.h};
                        padding: 0 28px;
                        padding: ${props => props.pd};
                        background-color: #d4212c;
                        color: #fff;
                        outline: none !important;
                        border: 1px solid #d4212c;
                        text-transform: uppercase;
                        font-family: Montserrat, sans-serif;
                        font-weight: 500;
                        font-size: 12px;
                        letter-spacing: 1px;
                        transition: 0.2s linear;
                        @media screen and (min-width: 768px) {
                          &:hover:not(:disabled),
                          &:focus:not(:disabled) {
                            background-color: #fff;
                            color: #d4212c;
                            svg {
                              stroke: #d4212c;
                              fill: #d4212c;
                              stop {
                                stop-color: #d4212c;
                              }
                              path {
                                stroke: #d4212c;
                              }
                            }
                          }
                        }
                        &:disabled {
                          opacity: 0.3;
                        }
                        &.no-stroke {
                          svg {
                            stroke: none;
                          }
                        }
                        svg {
                          margin-right: 0.75rem;
                          stroke: #fff;
                          fill: #fff;
                          stop {
                            stop-color: #fff;
                          }
                          path {
                            stroke: #fff;
                          }
                        }
                      }
                    `}
                  >
                    <AnchorLink href={pageHero.buttonLink}>{pageHero.buttonText}</AnchorLink>
                  </div>
                ) : (
                  <RedButton href={pageHero.buttonLink}>{pageHero.buttonText}</RedButton>
                )
              ) : (
                ''
              )}
            </CenteredContainer>
          </Container>
        </Hero>

        <div
          className={`franchise-sections ${isMobile ? 'mobile' : ''}`}
          css={css`
            padding-top: 90px;
          `}
        >
          {showStickyMenu && !isMobile ? (
            <StickyMenu
              whyCmlInView={whyCmlInView}
              quotesInView={quotesInView}
              asSeenInView={asSeenInView}
              supportInView={supportInView}
              stepsInView={stepsInView}
              joinFormInView={joinFormInView}
            />
          ) : null}
          <div
            className="ref-link"
            id="why-cml-ref"
            css={css`
              position: relative;
              top: -20vh;
            `}
            tabIndex="-1"
          />
          <WhyCml
            bgImages={bgImageData}
            showStickyMenu={showStickyMenu}
            sectionData={whyCmlSection}
            sectionRef={whyCmlRef}
          />

          <div className="ref-link" id="quotes-ref" tabIndex="-1" />
          <Quotes
            bgImages={bgImageData}
            showStickyMenu={showStickyMenu}
            sectionData={quotesSection}
            sectionRef={quotesRef}
          />

          <div className="ref-link" id="as-seen-in-ref" tabIndex="-1" />
          <AsSeenIn
            showStickyMenu={showStickyMenu}
            sectionData={logoSection}
            sectionRef={asSeenInRef}
          />
          <div className="ref-link" id="support-ref" tabIndex="-1" />

          <Support
            showStickyMenu={showStickyMenu}
            sectionData={supportSection}
            sectionRef={supportRef}
          />
          <div className="ref-link" id="steps-ref" tabIndex="-1" />

          <Steps
            bgImages={bgImageData}
            showStickyMenu={showStickyMenu}
            sectionData={stepsSection}
            sectionRef={stepsRef}
          />
          <div className="ref-link" id="join-form-ref" tabIndex="-1" />
          <JoinForm
            bgImages={bgImageData}
            pageImages={pageImageData}
            showStickyMenu={showStickyMenu}
            sectionData={joinFormSection}
            sectionRef={joinFormRef}
          />
        </div>
        <div className={`franchise-sections ${isMobile ? 'mobile' : ''}`}>
          <Faq bgImages={bgImageData} showStickyMenu={showStickyMenu} sectionData={faqSection} />
        </div>
      </div>
    </Fragment>
  );
};

export const query = graphql`
  query {
    contentfulPage(title: { eq: "Franchise" }) {
      contentModules {
        ... on ContentfulPageHero {
          heading
          description {
            description
          }
          backgroundImage {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          buttonText
          buttonLink
        }
        ... on ContentfulFranchisePageWhyCmlSection {
          id
          title
          heading
          whyCml {
            title
            heading
            subheading
            description {
              description
            }
            image {
              description
              fluid(maxWidth: 800) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
        ... on ContentfulFranchisePageQuotesSection {
          heading
          franchisePageQuotesSectionQuoteBlock {
            quotation
            author
            mediaMobile {
              description
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            media {
              description
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
        ... on ContentfulLogoSection {
          heading
          title
          logoBlock {
            url
            title
            image {
              description
              fluid(maxWidth: 120, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
        ... on ContentfulFranchisePageSupportSection {
          heading
          subheading
          description {
            description
          }
          secondaryImages {
            description
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          mainImage {
            description
            fluid(maxWidth: 1600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        ... on ContentfulFranchisePage12StepsSection {
          heading
          franchisePage12StepsSectionStep {
            title {
              title
            }
          }
        }
        ... on ContentfulFranchisePageFaqSection {
          heading
          franchisePageFaqSectionFaqBlock {
            question {
              question
            }
            answer {
              answer
            }
          }
        }
        ... on ContentfulFranchisePageJoinFormSection {
          formDisclaimerText {
            formDisclaimerText
          }
        }
      }
    }
    bgImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/background/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 915, quality: 100) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    pageImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/franchise/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 915, quality: 100) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default Franchise;
